import {BaseService} from "@/core/services/BaseService";

class TagService extends BaseService<any> {
  endpoint = "/tag";

  async all() {
    return await this.http().get(this.endpoint + "/all").then(res => {
      return res.data
    })
  }
}

export default new TagService();