import {BaseService} from "@/core/services/BaseService";

class MetricService extends BaseService<any> {
  endpoint = '/metric'

  async start(id) {
    return await this.http().get(this.endpoint + "/" + id + "/start").then(res => {
      return res.data;
    })
  }

  async chart(id) {
    return await this.http().get(this.endpoint + "/" + id + "/chart").then(res => {
      return res.data
    })
  }

  async export(id) {
    return await this.http().get(this.endpoint + "/" + id + "/export", {responseType: "blob"}).then(res => {
      return res
    })
  }


  async stop(id) {
    return await this.http().get(this.endpoint + "/" + id + "/stop").then(res => {
      return res.data;
    })
  }

  async complete(id) {
    return await this.http().get(this.endpoint + "/" + id + "/complete").then(res => {
      return res.data;
    })
  }

  async deleteMissing(id) {
    return await this.http().delete(this.endpoint + "/" + id + "/missing").then(res => {
      return res.data;
    })
  }

  async all() {
    return await this.http().get(this.endpoint + "/all").then(res => {
      return res.data;
    })
  }

  async my() {
    return await this.http().get(this.endpoint + "/my").then(res => {
      return res.data;
    })
  }

  async favorite() {
    return await this.http().get(this.endpoint + "/favorite").then(res => {
      return res.data;
    })
  }
}

export default new MetricService();