import {BaseService} from "@/core/services/BaseService";

class MetricValueService extends BaseService<any> {
  protected endpoint = '/metric-value';


  async updateValue(model) {
    return await this.http().put(this.endpoint, model).then(res => {
      return res.data;
    })
  }

  async updateAmount(id, model) {
    return await this.http().put(this.endpoint + "/" + id + "/amount", model).then(res => {
      return res.data;
    })
  }

  async sendEmail(id) {
    return await this.http().get(this.endpoint + "/" + id + "/email").then(res => {
      return res.data;
    })
  }

  async updateAmountEmail(id, model) {
    return await this.http().put(this.endpoint + "/" + id + "/amount/email", model).then(res => {
      return res.data;
    })
  }

  async getModel(id) {
    return this.http().get(this.endpoint + "/" + id + "/model").then(res => {
      return res.data
    })
  }
}

export default new MetricValueService()