import {defineStore} from "pinia";
import TaskTemplateService from "@/core/services/TaskTemplateService";
import {useLoadingStore} from "@/store/loading";

export const useTaskTemplateStore = defineStore('useTaskTemplateStore', {
  state: () => ({
    page: {
      total: 0,
      data: [],
      totalPages: 0
    },
    templates: <any>[]
  }),
  actions: {
    async list(filter: any) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      await TaskTemplateService.findAll<any>(filter).then(res => {
        this.page = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },

    loadTemplates(filter) {
      TaskTemplateService.findAll<any>(filter).then(res => {
        this.templates = res.data;
      })
    }
  }
});