import {defineStore} from "pinia";

export const usePanelStore = defineStore('panelStore', {
  state: () => ({
    data: {
      isShow: false,
      type: 'CLAIM',
      objectId: '',
      isLoading: true,
    },
    selectWorkspace: {
      isShow: false
    }
  }),
  getters: {
    loading(state) {
     return state.data.isLoading
    }
  },
  actions: {
    setPanelData(data: any) {
      console.log(data);
      this.data.isShow = data.isShow;
      this.data = data;
    },
    setPanelLoading(isLoading: boolean) {
      this.data.isLoading = isLoading
    },
    hidePanel() {
      this.data.isShow = false;
    },

    setSelectWorkspace(status: boolean) {
      this.selectWorkspace.isShow = status
    }
  }
})