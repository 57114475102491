import {defineStore} from "pinia";
import MetricValueService from "@/core/services/MetricValueService";
import {useLoadingStore} from "@/store/loading";

export const useMetricValueStore = defineStore('useMetricValueStore', {
  state: () => ({
    page: {
      total: 0,
      data: [],
      totalPages: 0
    },
    pageSecond: {
      total: 0,
      data: [],
      totalPages: 0
    },
    metricName: <string>'',
  }),
  actions: {
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      MetricValueService.findAll<any>(filter).then(res => {
        this.page = res
      }).finally(() => {
        loadingStore.setEntitiesLoading(false);
      })
    },
    listSecond(filter) {
      MetricValueService.findAll<any>(filter).then(res => {
        this.pageSecond = res
      })
    },
    setFormMetricName(name: string) {
      this.metricName = name
    }
  }
})