import {defineStore} from "pinia";

export const useLoadingStore = defineStore('loadingStore', {
  state: () => ({
    entitiesLoading: false,
    entityLoading: false,
  }),
  actions: {
    setEntitiesLoading(isLoading: boolean) {
      this.entitiesLoading = isLoading;
    },

    setEntityLoading(isLoading: boolean) {
      this.entityLoading = isLoading;
    }
  }
})