import {BaseService} from "@/core/services/BaseService";

class ClientService extends BaseService<any> {
  endpoint = "/client"

  async all() {
    return await this.http().get(this.endpoint + "/all").then(res => {
      return res.data;
    })
  }
}

export default new ClientService();