import {BaseService} from "@/core/services/BaseService";


class TaskTemplateService extends BaseService<any> {
  endpoint = '/task-template'

  async updateStatus(id, status) {
    return this.http().patch(this.endpoint + "/" + id + "/status/" + status).then(res => {
      return res.data
    })
  }
}

export default new TaskTemplateService();