import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {computed, ref, watch} from "vue";
import {IFilter} from "@/core/entity/IFilter";
import {ISearchFilter} from "@/core/entity/ISearchFilter";
import {ISearchField} from "@/core/entity/ISearchField";
import {useRouter} from "vue-router";
import {ICompany} from "@/core/entity/ICompany";
import UserService from "@/core/services/UserService";
import {usePanelStore} from "@/store/panel.store";
import TaskTemplateService from "../services/TaskTemplateService";
import CategoryService from "@/core/services/CategoryService";
import router from "@/router";
import {useLoadingStore} from "@/store/loading";
import TagService from "@/core/services/TagService";
import ClientService from "@/core/services/ClientService";
import GroupService from "@/core/services/GroupService";
import store from "@/store";
import CodeService from "@/core/services/CodeService";
import MetricService from "@/core/services/MetricService";
import {useMetricValueStore} from "@/store/metric.valute.store";
import {useTaskTemplateStore} from "@/store/task-template.store";

const PAGINATION_SIZE = 50;


export const LoadEntitiesFromEntityEvent = (action: Actions) => {
  const router = useRouter();
  const id = ref(router.currentRoute.value.params.id)
  const store = useStore();
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {search: ''},
  })

  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    paginationLoad()
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationLoad()
  };

  const paginationLoad = () => {
    store.dispatch(action, {id: id.value, filter: filter.value})
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
  }
}

export const SearchEntityEvent = (action: Actions, query: ISearchField[], fields: string[], expand: string[], entity: string) => {
  const store = useStore();
  const filter = ref<ISearchFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    query: query,
    entity: entity,
    fields: fields,
    expand: expand
  })

  const paginationLoad = () => {
    store.dispatch(action, filter.value)
  }
  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    store.dispatch(action, filter.value)
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    store.dispatch(action, filter.value);
  };
  const searchEvent = () => {
    paginationLoad();
  }
  const discardEvent = () => {
    filter.value.query = filter.value.query.map(item => Object.assign(item, {
      name: item.name,
      type: item.type,
      column: item.column,
      operator: item.operator,
      value: item.type === 'HIDDEN' ? item.value : ''
    }))
    paginationLoad();
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
    searchEvent,
    discardEvent
  }
}

export const LoadEntity = (action: Actions) => {
  const router = useRouter();
  const id = ref(router.currentRoute.value.params.id)
  const store = useStore();
  const reloadData = (id) => {
    store.dispatch(action, id)
  }
  watch(() => router.currentRoute.value.params.id, (cb) => {
    if (cb !== undefined) reloadData(cb);
  })
  reloadData(id.value);
  return {
    reloadData,
    id
  }
}


export const HandleState = () => {
  const store = useStore();
  const onCountrySelectedEvent = (country: string) => {
    store.dispatch(Actions.LOAD_STATE, country)
  }
  return {
    onCountrySelectedEvent
  }
}

export const CompanySelect = () => {
  const store = useStore();

  const loadCompany = () => {
    store.dispatch(Actions.LOAD_CLIENTS)

  }
  loadCompany();
  const clients = computed<ICompany[]>(() => store.state.CompanyModule.clientSelect)
  return {
    loadCompany,
    clients,
  }
}


export const UserSelect = () => {
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 500,
    key: {search: '', ep: ''},
  })
  const users = ref<any>([])
  const loadUser = () => {
    UserService.findAll<any>(filter.value).then(res => {
      users.value = res.data
    })
  }
  loadUser();
  return {
    users,
    loadUser
  }
}


export const LoadFilterObjects = (action: Actions, filterObject: any, expands: any[] = [], sort = '') => {
  const store = useStore();
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: PAGINATION_SIZE,
    key: {ep: expands.join(","), s: sort},
  })
  filter.value.key = Object.assign(filter.value.key, filterObject);
  const updateFilterObject = (filterObject) => {
    filter.value.key = Object.assign(filter.value.key, filterObject);
    paginationLoad()
  }
  const handleUpdatePage = (event) => {
    filter.value.paginationPage = event;
    paginationLoad()
  };

  const handleUpdateSize = (event) => {
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationLoad()
  };

  const paginationLoad = () => {
    store.dispatch(action, filter.value);
  }
  paginationLoad();
  return {
    filter,
    handleUpdatePage,
    paginationLoad,
    handleUpdateSize,
    updateFilterObject,
  }
}

export const PinaFilterObjects = (store: any, filterObject: any, expands: any[] = [], sort = '', secondList: boolean = false, size: number = 50) => {
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: size,
    key: {ep: expands.join(","), s: sort},
  })
  filter.value.key = Object.assign(filter.value.key, filterObject);
  const updateFilterObject = (filterObject) => {
    filter.value.key = Object.assign(filter.value.key, filterObject);
    paginationDataLoad()
  }
  const paginationDataLoad = () => {
    if (secondList) {
      store.listSecond(filter.value)
    } else {
      store.list(filter.value);
    }
  }
  paginationDataLoad();

  const handleUpdatePage = (event) => {
    console.log("handleUpdatePage ", event)
    filter.value.paginationPage = event;
    paginationDataLoad()
  };

  const updateSort = (str: string) => {
    filter.value.key.s = str;
  }
  const handleUpdateSize = (event) => {
    console.log("handleUpdateSize ", event);
    filter.value.paginationPage = 0;
    filter.value.paginationSize = Number(event);
    paginationDataLoad()
  };
  return {
    updateSort,
    handleUpdatePage,
    handleUpdateSize,
    paginationDataLoad,
    updateFilterObject,
    filter,
  }
}

export const LoadPanel = () => {
  const store = usePanelStore();
  const loadPanel = (id, type) => {
    store.setPanelData({objectId: id, type: type, isShow: true, isLoading: true})
  }
  return {
    loadPanel
  }
}

export const GetSpaceId = () => {
  const vuexStore = useStore();
  const spaceId = computed(() => vuexStore.getters.currentUser.currentWorkspaceId);
  return {
    spaceId
  }
}

export const getCurrentSpaceAccess = () => {
  const user = computed(() => store.getters.currentUser);
  const spaceAccess = ref({
    accessMetrics: false,
    accessWorks: false,
    accessTasks: false,
    accessTimer: false,
    accessDocs: false,
    canAddTask: true,
    canAddWork: true,
    canAddMetric: true,
    canAddTraining: true,
  })
  if (user.value.space.access) {
    spaceAccess.value = user.value?.space?.access
  } else {
    spaceAccess.value = {
      accessMetrics: false,
      accessWorks: false,
      accessTasks: false,
      accessTimer: false,
      accessDocs: false,
      canAddTask: true,
      canAddWork: true,
      canAddMetric: true,
      canAddTraining: true,
    }
  }
  watch(user, cb => {
    spaceAccess.value = cb?.space?.access;
  })

  return {
    spaceAccess,
  }
}

export const getCurrentSpaceName = () => {
  const vuexStore = useStore();
  const spaceName = ref('');
  const spaceId = GetSpaceId().spaceId.value;
  const workspaces = computed(() => vuexStore.getters.currentUser.workspaces);
  const getSpaceName = () => {
    if (workspaces.value) {
      spaceName.value = workspaces.value.find(item => item.id === spaceId)?.name
    } else {
      return ''
    }
  }
  watch(workspaces, () => {
    getSpaceName()
  })
  getSpaceName();
  return {
    spaceName,
    getSpaceName
  }
}
export const TeamSelect = (isCompany = false) => {
  const teams = ref<any>([])
  const loadTeams = (isCompany) => {
    GroupService.getTeams(isCompany).then(res => {
      teams.value = res;
    })
  }
  loadTeams(isCompany);
  return {
    teams,
  }

}

export const TemplateSelect = () => {
  const spaceId = GetSpaceId();

  const taskTemplateStore = useTaskTemplateStore();
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 500,
    key: {search: '', ep: '', workspaceId: spaceId.spaceId.value, status: 'ACTIVE'},
  })
  const loadTemplate = () => {
    taskTemplateStore.loadTemplates(filter.value);
  }
  const templates = computed(() => taskTemplateStore.templates);
  loadTemplate();
  return {
    templates,
    loadTemplate,
  }
}

export const MetricGroupsSelect = () => {
  const vuexStore = useStore();
  const currentUser = computed(() => vuexStore.getters.currentUser);
  const metricGroups = ref<any>([])
  const loadMetricGroups = () => {
    GroupService.getMetricGroup(currentUser.value.currentWorkspaceId).then(res => {
      metricGroups.value = res
    })
  }
  loadMetricGroups()
  return {
    metricGroups,
    loadMetricGroups
  }
}

export const CategorySelect = () => {
  const categories = ref<any>([]);
  const filter = ref<IFilter>({
    paginationPage: 1,
    paginationSize: 500,
    key: {search: '', ep: ''},
  })
  const loadCategories = () => {
    CategoryService.findAll<any>(filter.value).then(res => {
      categories.value = res.data;
    })
  }
  loadCategories();
  return {
    categories,
    loadCategories,
  }
}

export const PinaLoadEntity = (store) => {
  const loadingStore = useLoadingStore();
  const id = ref(router.currentRoute.value.params.id)
  const loadEntity = () => {
    loadingStore.setEntityLoading(true)
    store.get(id.value);
  }

  // watch(() => router.currentRoute.value.params.id, (cb) => {
  //   if (cb !== undefined) reloadEntityWithId(cb);
  // })
  const reloadEntityWithId = (id) => {
    loadingStore.setEntityLoading(true)
    store.get(id)
  }

  loadEntity()
  return {
    loadEntity,
    reloadEntityWithId
  }
}

export const ReportChartEvent = (store, id) => {
  const filterChart = ref({
    paginationPage: 1,
    paginationSize: 20,
    key: {order: 'desc'}
  })
  const loadReportChart = () => {
    store.chart(id.value, filterChart.value)
  };

  loadReportChart()
  return {
    filterChart,
    loadReportChart,
  }
}


export const TagSelect = () => {
  const tags = ref<any>([]);
  const loadTagSelect = () => {
    TagService.all().then(res => {
      tags.value = res;
    })
  }
  loadTagSelect()
  return {
    tags
  }
}

export const ClientSelect = () => {
  const clients = ref<any>([]);
  const loadClientSelect = () => {
    ClientService.all().then(res => {
      clients.value = res
    })
  }
  loadClientSelect();
  return {
    clients
  }
}


export const AdminCompanySelect = () => {
  const companies = ref<any>([]);
  const loadClientSelect = () => {
    GroupService.getCompanies().then(res => {
      companies.value = res
    })
  }
  loadClientSelect();
  return {
    companies
  }
}

export const TimeZoneSelect = () => {
  const timezones = ref<any>([]);
  const loadTimeZoneSelect = () => {
    UserService.getTimeZones().then(res => {
      timezones.value = res
    })
  }
  loadTimeZoneSelect();
  return {
    timezones
  }
}


export const BreakDownSelect = () => {
  const breakdowns = ref<any>([]);
  const loadBreakDown = () => {
    CodeService.findAll<any>({breakdown: true}).then(res => {
      breakdowns.value = res;
    })
  }
  loadBreakDown();
  return {
    breakdowns,
    loadBreakDown
  }
}


export const MetricSelect = () => {
  const metrics = ref<any>([]);
  const loadMetrics = () => {
    MetricService.findAll<any[]>({all: true}).then(res => {
      return metrics.value = res;
    })
  }
  loadMetrics();
  return {
    loadMetrics,
    metrics,
  }
}
export const WorkspaceSelect = () => {
  const workspaces = ref<any>([])
  const loadWorkspaces = () => {
    GroupService.getWorkspaces().then(res => {
      workspaces.value = res;
    })
  }
  loadWorkspaces();
  return {
    workspaces,
  }
}


export const GetMetricFormName = () => {
  const metricValueStore = useMetricValueStore();
  const metricName = computed(() => metricValueStore.metricName);
  return {
    metricName
  }
}


export const CurrentUser = () => {
  const vuexStore = useStore();
  const currentUser = computed(() => vuexStore.getters.currentUser);
  return {
    currentUser
  }
}