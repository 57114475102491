import {BaseService} from "@/core/services/BaseService";

class CodeService extends BaseService<any> {
  endpoint = "/code";

  async getOptions(id) {
    return await this.http().get(this.endpoint + "/" + id + "/options").then(res => {
      return res.data
    })
  }
}

export default new CodeService();